.text {
    display: block;
    /* avoids Grid from breaking (overflow) ... extra long text strings like urls */
    max-width: 100%;
    overflow: hidden;
    font-weight: 400;
  }
  
  .text--linethrough {
    text-decoration: line-through;
  }
  
  span.text {
    width: auto;
    display: inline;
  }
  
  .text .icon,
  .text .icon-img {
    display: inline-block;
    vertical-align: middle;
    /* fill: var(--color-secondary); */
  }
  
  /* Size */
  
  .text--48 {
    font-size: var(--font-size-xxxxl);
    line-height: 1;
    font-weight: 700;
  }
  .text--32 {
    font-size: var(--font-size-xxxl);
    line-height: 1;
    font-weight: 700;
  }
  
  .text--22 {
    font-size: var(--font-size-xxl);
    line-height: 1.2;
  }
  .text--20 {
    font-size: var(--font-size-xl);
  }
  .text--18 {
    font-size: var(--font-size-l);
  }
  .text--16 {
    font-size: var(--font-size-m);
  }
  .text--14 {
    font-size: var(--font-size-s);
  }
  .text--12 {
    font-size: var(--font-size-xs);
  }
  
  /* alignment */
  
  .text--left {
    text-align: left;
  }
  .text--center {
    text-align: center;
  }
  .text--right {
    text-align: right;
  }
  
  /* colors */
  
  .text--primary {
    color: var(--primary);
  }
  .text--secondary {
    color: var(--secondary);
  }
  .text--sub-color {
    color: var(--sub-color);
  }
  .text--bg {
    color: var(--bg);
  }
  .text--grey1 {
    color: var(--grey1);
  }
  .text--grey2 {
    color: var(--grey2);
  }
  .text--grey3 {
    color: var(--grey3);
  }
  .text--grey4 {
    color: var(--grey4);
  }
  .text--green-dark {
    color: var(--green-dark);
  }
  .text--green-light {
    color: var(--green-light);
  }
  .text--liliac-dark {
    color: var(--liliac-dark);
  }
  .text--success {
    color: var(--yes-success);
  }
  .text--red {
    color: var(--red);
  }
  .text--yellow {
    color: var(--yellow);
  }
  .text--orange {
    color: var(--orange);
  }
  
  /* Misc */
  
  .text--nowrap {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .text--uppercase {
    text-transform: uppercase;
  }
  
  .text--bold {
    font-weight: 700;
  }

  .text--underline {
    text-decoration: underline;
  }
  
  .text--italic {
    font-style: italic;
  }
  
  .text--block {
    display: block !important;
  }
  
  .text--capitalize {
    text-transform: capitalize;
  }
  
  .text--blurred {
    filter: blur(4px);
  }
  
  /* aside components */
  
  .text-wrapper {
    display: block;
  }
  
  .text-wrapper--center {
    text-align: center;
  }
  
  .text-wrapper--left {
    text-align: left;
  }
  
  .text-wrapper--right {
    text-align: right;
  }
  
  .text-wrapper__bg {
    display: inline-block;
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
    padding-right: var(--space-m);
    padding-left: var(--space-m);
    background: var(--gradi);
  }
  
.text--lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}