.image-slider {
    position: relative;
  }
  .image-slider__items {
    position: relative;
    display: flex;
    width: 100%;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    background: var(--grey1);
  }
  .image-slider__item {
    flex: 0 0 100%;
    scroll-snap-align: start;
  }
  
  .image-slider__item img {
    width: 100%;
  }
  
  .image-slider__meta {
    position: absolute;
    bottom: var(--space-xxs);
    left: var(--space-xs);
    pointer-events: none;
  }
  
  /* Force 3/2 ratio */
  
  .image-slider--force-ratio .image-slider__item {
    position: relative;
    width: 100%;
    padding-bottom: 66.66667%;
  }
  
  .image-slider--force-ratio .image-slider__item img,
  .image-slider--force-ratio .image-placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .image-slider--fixed-height .image-slider__item  {
    padding-bottom: 0;
    height: 250px;
  }