.button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  vertical-align: top;
  padding: var(--space-s) var(--space-l);
  line-height: var(--space-l);
  border-radius: var(--space-l);
  font-size: var(--font-size-s);
  font-family: var(--font-family-alt);
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
}

.button--wrap {
  white-space: normal;
}

.button--inline {
  display: inline-block;
  width: auto;
}

.button .icon--before {
  margin-right: 0.25rem;
}

.button .icon--after {
  margin-left: 0.25rem;
}

/* variants */

.button--primary {
  background: var(--liliac-dark);
  color: var(--bg);
}

.button--secondary {
  background: var(--grey3);
  color: var(--primary);
}

.button--secondary-alt {
  background: var(--grey4);
  color: #fff;
}

.button--success {
  background: var(--yes-success);
  color: #fff;
}

.button--error {
  background: var(--red);
  color: #fff;
}

.button--link-alt {
  color: var(--green-light);
}

.button--error-link {
  color: var(--red);
}

.button--disabled {
  background: var(--grey3);
  color: #fff;
}

.button--outlined {
  border: 1px solid #007AA3;
  background-color: transparent;
  color: #007AA3;
}

.button--cashback {
  background: linear-gradient(211.66deg, #A9824F -20.26%, #F2D893 61.81%, #FFE79F 72.24%, #FEE69E 72.26%, #E2C07C 102.77%);
  color: #000;
}

.button--cashback-offer {
  background: #f5d247;
  border-radius: 0;
}

/* sizes */

.button--sm {
  padding: var(--space-xs) var(--space-s);
}

.button--lg {
  padding: var(--space-m) var(--space-xl);
  border-radius: var(--space-xl)
}

/* custom */

.button--google {
  background: #4285f4;
  color: #fff;
  font-weight: 700;
  font-size: var(--font-size);
}

.button--apple {
  background: var(--primary);
  color: var(--bg);
  font-weight: 700;
  font-size: var(--font-size);
}

.button--facebook {
  background: #4267b2;
  color: #fff;
  font-weight: 700;
  font-size: var(--font-size);
}
