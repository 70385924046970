:root {
  /* fonts */
  --font-family: "Open Sans", sans-serif;
  --font-family-alt: "Rubik", sans-serif;
  --font-size-xxs: 10px;
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-l: 18px;
  --font-size-xl: 20px;
  --font-size-xxl: 22px;
  --font-size-xxxl: 32px;
  --font-size-xxxxl: 48px;
  /* spacing */
  --space-xxxs: 2px;
  --space-xxs: 4px;
  --space-xs: 8px;
  --space-s: 12px;
  --space-m: 16px;
  --space-l: 24px;
  --space-xl: 32px;
  --space-xxl: 48px;
  --space-xxxl: 64px;
  /* colors */
  --primary: #fff;
  --secondary: #aec1c5;
  --sub-color: #80969b;
  --bg: #111b1f;
  --bg-rgb: 17, 27, 31;
  --grey1: #1f2f35;
  --grey2: #2d3d43;
  --grey3: #44565e;
  --green-dark: #5cdeff;
  --green-light: #5cdeff;
  --liliac-dark: #5f5cff;
  --red: #ff5789;
  --yellow: #ffe27b;
  --orange: #ff8863;
  --messenger: #0f73d2; /* 0084ff */
  --yes-success: #27ae60;
  --gradi-overlay: linear-gradient(132.87deg, #1f2f35 8.12%, #111b1f 53.12%);
  --gradi-other: linear-gradient(360deg, #132328 0%, rgba(19, 35, 40, 0) 100%);
  --gradi: linear-gradient(103.96deg, #006b86 6.41%, #0c313d 100.74%);
  --gradi-alt: linear-gradient(103.96deg, #6764ff 6.41%, #5cdeff 100.74%);
  /* env */
  --c-emission-a: #49a359;
  --c-emission-b: #6db558;
  --c-emission-c: #c5d553;
  --c-emission-d: #ecdf33;
  --c-emission-e: #f3bb44;
  --c-emission-f: #e57738;
  --c-emission-g: #db3733;
}

[data-theme="light"] {
  --primary: #111b1f; /* #225e75 */
  --secondary: #6a787c;
  --sub-color: #8e9ea1;
  --bg: #fff;
  --bg-alt: #aec1c5;
  --grey1: #f0f3f3;
  --grey2: #dae3e4;
  --grey3: #97a8ab;
  --grey4: #44565e;
  --green-dark: #007aa3;
  --green-light: #5cdeff;
  --green-light-2: #DAE3E4;
  --liliac-dark: #4543d2;
  --red: #e54f7c;
  --yellow: #debe4e;
  --orange: #ff8863;
  --gradi-overlay: linear-gradient(132.87deg, #fff 8.12%, #fff 53.12%);
  --gradi-other: linear-gradient(
    211.66deg,
    #a9824f -20.26%,
    #f2d893 61.81%,
    #ffe79f 72.24%,
    #fee69e 72.26%,
    #e2c07c 102.77%
  );
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  overflow-x: hidden;
  margin: 0;
  background: var(--bg);
  color: var(--primary);
  font-family: var(--font-family);
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  min-height: 100vh;
}

body:after {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: env(safe-area-inset-top);
  content: "";
  background: rgba(17, 27, 31, 0.8);
  backdrop-filter: blur(4px);
  z-index: 1;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
table,
hr {
  margin: 0;
}

a {
  color: inherit;
  font: inherit;
  text-decoration: none;
}

a.colored {
  color: var(--green-dark);
}

a.underlined {
  text-decoration: underline;
}

img {
  display: block;
  max-width: 100%;
}

button,
input,
select {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  background: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
}

table {
  width: 100%;
  
}
td,th {
  vertical-align: top;
}

svg {
  fill: currentColor;
}

dl {
  display: flex;
  /* justify-content: space-between; */
  gap: 1rem;
  margin: 0;
}

dt,
dd {
  margin: 0;
  padding: 0;
}

dd {
  text-align: right;
}
dl span {
  display: block;
}

table {
  width: 100%;
}

hr {
  outline: 0;
  border: 0;
  height: 1px;
  background: var(--grey3);
  opacity: 0.2;
}

/* lists */

.list-unstyled {
  padding: 0;
  list-style: none;
}

.list-bullet {
  padding: 0;
  list-style: none;
}

.list-bullet li {
  position: relative;
  margin: 8px 0;
  padding-left: 20px;
}

.list-bullet li:after {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid var(--green-dark);
  background: var(--green-light);
}

html {
  margin: 0;
}

body {
  height: 100%;
  margin: 0;
  overflow-y: auto;
}

/* .app {
  max-width: 768px;
  margin: auto;
} */

.is-mobile {
  display: block;
}

.is-desktop {
  display: none;
}

@media(min-width: 1024px) {
  .is-mobile {
    display: none;
  }
  
  .is-desktop {
    display: block;
  }
}

/*      =========       */

/* 

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  background: #F0F3F3;
}

h1,h2,h3,h4,h5,p, ul, ol, img {
  margin: 0;
}

img {
  display: block;
  width: 100%;
}

h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
}

h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.04em;
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #6A787C;
}

.section {
  padding: 1rem;
  scroll-margin-top: 5rem;
  scroll-snap-margin-top: 5rem;
}

.section--primary {
  position: relative;
  background: #fff;
  z-index: 1;
}

.section__title {
  padding-bottom: 1rem;
}

.section--special {
  background: linear-gradient(211.66deg, #A9824F -20.26%, #F2D893 61.81%, #FFE79F 72.24%, #FEE69E 72.26%, #E2C07C 102.77%);
}

.section--orders {
  background: #DBDCF5;
}

.section--translate img {
  width: auto;
}

.scroll {
  overflow: hidden;
  background: #fff;
}
.scroll--v {
  overflow-x: auto;
}

.tabs {
  display: flex;
  padding: 0;
  list-style: none;
  vertical-align: auto;
}

.tabs__button {
  display: block;
  padding: 1rem;
  border: 0;
  outline: none;
  background: none;
  color: #6A787C;
  font: inherit;
  font-size: 14px;
  line-height: 1rem;
  text-transform: uppercase;
  border-bottom: 4px solid #6A787C;
  white-space: nowrap;
}

.sticky {
  position: sticky;
  top: 0;
}

.header {
  background: #fff;
}

.clever {
  margin-top: -32px;
}

.clever__title {
  width: 100%;
  background: #fff;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  padding: 16px 0 0;
  align-items: center;
  text-align: center;
}

.item {
  display: flex;
  gap: 0.5rem;
  padding: 1rem 0;
  border-top: 1px solid #fff;
}

.item h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.item p {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  color: #6A787C;
}

.item__cover {
  width: 104px;
  flex-grow: 0;
  flex-shrink: 0;
}

.item__content {
  flex-grow: 1;
  flex-shrink: 1;
}

p.item__price {
  color: #007AA3;
  font-weight: 700;
}

.item__actions {
  align-self: center;
}

.btn-quantity {
  display: block;
  padding: 0;
  border: 0;
  outline: none;
  background-color: transparent;
}

.item__quantity {
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item__quantity-nr {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  background-color: #5F5CFF;
  color: #fff;
}

.select-bar {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-top: 1px solid #DAE3E4;
  background: #F0F3F3;
}

.select-btn {
  display: flex;
  padding: 0.75rem 1.5rem;
  gap: 0.5rem;
  border: 0;
  outline: none;
  background: #5F5CFF;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 2rem;
}

.select-btn svg {
  display: block;
  align-self: flex-end;
}

.btn-check {
  display: block;
  padding: 0;
  border: 0;
  outline: none;
  background-color: transparent;
} */
