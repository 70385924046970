.icon {
  display: block;
  width: 24px;
  height: 24px;
  fill: currentColor;
}

.icon svg {
  display: block;
}

.icon--12 {
  width: 12px;
  height: 12px;
}

.icon--14 {
  width: 14px;
  height: 14px;
}

.icon--18 {
  width: 18px;
  height: 18px;
}

.icon--20 {
  width: 20px;
  height: 20px;
}


.icon--24 {
  width: 24px;
  height: 24px;
}

.icon--32 {
  width: 32px;
  height: 32px;
}

.icon--36 {
  width: 36px;
  height: 36px;
}


.icon--48 {
  width: 48px;
  height: 48px;
}

.icon--64 {
  width: 64px;
  height: 64px;
}

.icon--84 {
  width: 84px;
  height: 84px;
}

.icon--center {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.icon--right {
  margin-left: auto;
  text-align: right;
}

.icon--blue {
  color: #5CDEFF;
}

.icon--blue-alt {
  color: #007AA3;
}