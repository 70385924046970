.tag {
  border-radius: 10px;
  padding: var(--space-xxxs) var(--space-xs);
  background: var(--grey2);
  color: #fff;
  font-size: var(--font-size-xxs);
  text-transform: uppercase;
}

.tag--highlighted {
  background: var(--gradi-alt);
}

button.tag {
  color: var(--green-light);
}

.tag__icon {
  display: inline-block;
  vertical-align: top;
  width: 15px;
  height: 15px;
  padding: 1px;
  margin-right: 0.25rem;
}

.tag--required {
  background: #BA2E58;
  color: #fff;
}

.tag--optional {
  background: #8E9EA1;
  color: #fff;
}