.page-sheet__content {
    max-width: 768px;
    margin: auto;
}

.page-sheet__close {
    display: none;
}

@media(min-width:1024px) {
    .page-sheet__content {
        text-align: center;
    }
    .page-sheet__close {
        display: flex;
        justify-content: center;
    }
    .page-sheet__close .button {
        width: auto;
    }
}