.section {
    position: relative;
    padding: 0 var(--space-m);
  }
  
  .section--filled {
    padding: var(--space-m);
  }
  
  .section--double-padding {
    padding: var(--space-xl);
  }
  
  /* fills */
  
  .section--primary {
    background-color: var(--primary);
  }
  .section--secondary {
    background-color: var(--secondary);
  }
  .section--sub-color {
    background-color: var(--sub-color);
  }
  .section--bg {
    background-color: var(--bg);
  }
  .section--grey1 {
    background-color: var(--grey1);
  }
  .section--grey2 {
    background-color: var(--grey2);
  }
  .section--grey3 {
    background-color: var(--grey3);
  }
  .section--green-dark {
    background-color: var(--green-dark);
  }
  .section--liliac-dark {
    background-color: var(--liliac-dark);
  }
  .section--red {
    background-color: var(--red);
  }
  .section--yellow {
    background-color: var(--yellow);
  }
  .section--orange {
    background-color: var(--orange);
  }
  .section--gradi-overlay {
    background: var(--gradi-overlay);
  }
  .section--gradi-other {
    background: var(--gradi-other);
  }
  .section--green-light-2 {
    background-color: var(--green-light-2);
  }
  
  /* bordered */
  
  .section--border-grey1 {
    border: 1px solid var(--grey1);
  }
  .section--border-grey3 {
    border: 1px solid var(--grey3);
  }
  
  /* Rounded */
  
  .section--rounded {
    border-radius: 20px;
  }
  
  .section--rounded-right-bottom {
    border-radius: 0 20px 20px 20px;
  }
  
  .section--rounded-right-top {
    border-radius: 20px 20px 20px 0;
  }
  
  /* arrows */
  
  .section--arrow:after {
    position: absolute;
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 1rem solid transparent;
  }
  
  /* arrows -> down */
  
  .section--arrow-down:after {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -1rem;
  }
  
  .section--grey1.section--arrow-down:after {
    border-top-color: var(--grey1);
  }
  
  .section--grey2.section--arrow-down:after {
    border-top-color: var(--grey2);
  }
  
  /* arrows -> left */
  
  .section--arrow-left:after {
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -1rem;
  }
  
  .section--grey1.section--arrow-left:after {
    border-right-color: var(--grey1);
  }
  
  .section--grey2.section--arrow-left:after {
    border-right-color: var(--grey2);
  }
  
  /* arrows -> right */
  
  .section--arrow-right:after {
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -1rem;
  }
  
  .section--grey1.section--arrow-right:after {
    border-left-color: var(--grey1);
  }
  
  .section--grey2.section--arrow-right:after {
    border-left-color: var(--grey2);
  }
  

  /* specifics*/ 

  #section__specials .menu-item:first-child {
    border-top: 0;
    padding-top: 0;
  }