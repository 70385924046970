.btn {
  display: block;
  width: 40px;
  height: 40px;
  padding: 11px;
  border-radius: 50%;
  background: var(--grey2);
  color: var(--secondary);
}

.btn--inline {
  display: inline-block;
  vertical-align: top;
}

/* Sizes */

.btn--24 {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem;
}

.btn--24 .icon {
  width: 1rem;
  height: 1rem;
}

.btn--32 {
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
}

.btn--40 {
  width: 2,5rem;
  height: 2,5rem;
  padding: 0.5rem;
}

.btn--40 .icon {
  width: 1.5rem;
  height: 1.5rem;
}

.btn--64 {
  width: 4rem;
  height: 4rem;
  padding: 1rem;
}

.btn--64 .icon {
  width: 2rem;
  height: 2rem;
}

.bnt--icon-only {
  padding: 0;
  background: none;
}

.bnt--icon-only .icon {
  width: 100%;
  height: 100%;
}

.btn--logo {
  padding: var(--space-xs);
  background: var(--liliac-dark);
  color: var(--primary);
}

.btn--active {
  background: var(--liliac-dark);
  color: var(--primary);
}

.btn--secondary {
  background: var(--grey4);
  color: var(--bg);
}

.btn--secondary-alt {
  color: var(--grey4);
}

.btn--outlined {
  position: relative;
  background: none;
}

.btn--outlined:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  border: 1px solid var(--grey2);
  border-radius: 50%;
  pointer-events: none;
}

.btn--success {
  background: var(--yes-success);
  color: #fff;
}

.btn--error {
  color: var(--red);
  /* color: #fff; */
}

.btn--center {
  margin-right: auto;
  margin-left: auto;
}

.btn--link {
  background: none;
}

.btn--disabled {
  pointer-events: none;
}

.btn--hidden {
  pointer-events: none;
  opacity: 0;
}
