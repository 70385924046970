.quantity-field {
  display: flex;
}
.quantity-field--vertical {
  flex-direction: column;
  flex-direction: column-reverse;
}

.quantity-field__add,
.quantity-field__remove {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: var(--sub-color);
  font-size: 30px;
  text-align: center;
}

.quantity-field__input {
  width: 40px;
  height: 40px;
  background: var(--primary);
  color: var(--bg);
  text-align: center;
  border-radius: 5px;
}

.quantity-field__input::-webkit-outer-spin-button,
.quantity-field__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantity-field__input[type="number"] {
  -moz-appearance: textfield;
}
