.check {
  position: relative;
  display: block;
  padding: 0.25rem 0;
}

.check:before {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
}

.checked--l:before {
  width: 2rem;
  height: 2rem;
}

.check:before {
  border-radius: 2px;
  background: var(--grey3);
  transition: all 0.32s ease;
}

.check--checked:before {
  background: var(--green-light);
}

.check__input {
  display: none;
}

.check__label {
  padding-left: 1.5rem;
}

.check__label  span {
  float: right;
  color: #007AA3;
}

.checked--l .check__label {
  display: block;
  padding-top: 0.5rem;
  padding-left: 3rem;
}

.check__icon {
  position: absolute;
  top: 0.5rem;
  left: 0;
  margin: 1px;
  opacity: 0;
}

.check--checked .check__icon {
  opacity: 1;
}
