.sub-heading {
    display: flex;
    padding-bottom: var(--space-xxs);
    justify-content: space-between;
    border-bottom: 1px solid var(--grey2);
  }
  .sub-heading__title {
    color: var(--secondary);
  }
  
  .sub-heading__title,
  .sub-heading__aside {
    font-family: var(--font-family-alt);
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size-xs);
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }
  