.icon-img {
  display: block;
  width: 24px;
  height: 24px;
}

.icon-img svg {
  fill: none;
}

.icon-img--14 {
  width: 14px;
  height: 14px;
}

.icon-img--18 {
  width: 18px;
  height: 18px;
}

.icon-img--24 {
  width: 24px;
  height: 24px;
}

.icon-img--32 {
  width: 32px;
  height: 32px;
}

.icon-img--48 {
  width: 48px;
  height: 48px;
}

.icon-img--64 {
  width: 64px;
  height: 64px;
}

.icon-img--84 {
  width: 84px;
  height: 84px;
}

.icon-img--center {
  margin-right: auto;
  margin-left: auto;
}

.icon-img--right {
  margin-left: auto;
}
