.meal {
  padding-bottom: 9rem;
}

.meal__sticky {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}
.meal__sticky-wrapper {
  position: relative;
  max-width: 768px;
  margin: auto;
}

.meal__sticky-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background: #AEC1C5;
  border-top: 1px solid #dae3e4;
  opacity: 0.95;
}


.meal__sticky-inner {
  position: relative;
}

.meal__add {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.meal__add .button {
  width: auto;
}

.meal__close {
  position: fixed;
  bottom: 6rem;
  left: 0;
  right: 0;
}

.meal__close-wrapper {
  position: relative;
  max-width: 768px;
  margin: auto;
}

.meal__close .btn {
  position: absolute;
  right: 1rem;
  bottom: 0;
  background: #44565E;
  color: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,.5);
}
.meal__alt {
  position: fixed;
  bottom: 5.75rem;
  right: 5rem;
  left: 1rem;
}

.meal__fav {
  position: absolute;
  top: 0rem;
  right: 0;
  gap: 0.5rem;
  display: flex;
  padding: 0.25rem 1rem;
  background: #113A47;
  color: #fff;
  border-radius: 0 5px 0 10px;
}

.meal__fav > div {
  display: flex;
  align-items: center;
  gap: 0.125rem;
}